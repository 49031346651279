// eslint-disable-next-line
const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
const minCharacters = /(?=.{8,}$)/;
const requiredMinUpperChar = /(?=.*[A-Z])/;
const requiredMinOneDigit = /(?=.*[0-9])/;

export const composeValidators = (...validators) => (value) => validators.reduce((error, validator) => error || validator(value), undefined);

export const required = (value) => (value ? undefined : 'Es un campo requerido');

export const minLength = (minLength) => (value) =>
  (value.length < minLength ? `Debe tener al menos ${minLength} carácteres` : undefined);

export const email = (value) => (value && regexEmail.test(value)
  ? undefined : 'El mail ingresado es incorrecto');

export const validPassword = (value) => {
  let errors = [];
  if (value) {
    errors.push({
      text: 'Al menos 8 caracteres',
      completed: minCharacters.test(value)
    });
    errors.push({
      text: 'Al menos 1 mayuscula',
      completed: requiredMinUpperChar.test(value)
    });
    errors.push({
      text: 'Al menos un digito',
      completed: requiredMinOneDigit.test(value)
    });
  }

  return errors.length > 0 && errors.every(error => error.completed) ? undefined : errors;
};

export const validateDate = ({ startDate, endDate }) => {
  
  if(!startDate || !endDate) return 'Por favor ingrese las 2 fechas.';
  if(startDate === endDate) return '';
  
  let d1 = new Date(`${startDate} 00:00:00`);
  let d2 = new Date(`${endDate} 23:59:59`);

  if(d1 > d2) return 'La primer fecha no puede ser mayor a la segunda.'

  return '';
};