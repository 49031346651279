// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: `${process.env.REACT_APP_API_KEY}`,
//   authDomain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
//   projectId: `${process.env.REACT_APP_PROJECT_ID}`,
//   storageBucket: `${process.env.REACT_APP_STORAGE_BUCKET}`,
//   messagingSenderId: `${process.env.REACT_APP_MESSAGING_SENDER_ID}`,
//   appId: `${process.env.REACT_APP_APP_ID}`
// };

const firebaseConfig = {
  apiKey: "AIzaSyAytvJaSN-m62PcTGyJWw9EPobUZ2kEuJI",
  authDomain: "enviadordewpps.firebaseapp.com",
  projectId: "enviadordewpps",
  storageBucket: "enviadordewpps.appspot.com",
  messagingSenderId: "1071871635594",
  appId: "1:1071871635594:web:691c678e5c3e9064a77d73"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;