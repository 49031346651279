import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import ShowErrorLeads from "../shared/ShowErrorLeads/ShowErrorLeads";
// import ClientRoutes from "./client";
import Login from "./client/auth/login";
import FilterByZone from "./client/enviadorWpps/filterByZone";
import WppSender from "./client/enviadorWpps/WppSender";
import Filters from "./client/filters/Filters";
import Home from "./client/home";
import UserTable from "./client/user-table";
import { ProtectedRoute } from "./hooks/ProtectedRoute";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route index element={<Login />} />
        {/* DESCOMENTAR PARA FINES DE DESARROLLO */}
        {/* <Route element={<Home />} path='home'/> */}
        {/* <Route element={<WppSender />} path='enviador-de-wpps'/> */}
        <Route element={<ProtectedRoute redirectTo="/" />}>
          <Route element={<Home />} path="home" />
          <Route element={<Filters />} path="filters" />
          <Route element={<WppSender />} path="filters/enviador-de-wpps" />
          <Route element={<FilterByZone />} path="filters/region" />
          <Route element={<UserTable />} path="home/user-table" />
          <Route element={<ShowErrorLeads />} path="home/error-leads" />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
