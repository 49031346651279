import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#66bb6a",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BasicModal = ({handleOpen, handleClose, open}) => {

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Mensajes Enviados
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              NO OLVIDES CONSULTAR LA PESTAÑA DE "ERROR LEADS" PARA SABER SI HA FALLADO ALGÚN ENVÍO.
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default BasicModal;
