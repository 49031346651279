import React from "react";
import TextField from "@mui/material/TextField";
import css from "./selectDate.module.css";

const SelectDate = ({ input, label }) => {
  return (
    <div
      className={css.containerTextField}
    >
      <p className={css.labelText}>{label}</p>
      <TextField
        id="input-with-icon-textfield"
        sx={{input: {textAlign: "center"}}}
        InputProps={{
          style: {fontSize: 50, color: 'white'}
        }}
        {...input}
        type={'date'}
        variant="filled"
      />
    </div>
  );
};

export default SelectDate;
