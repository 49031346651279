import React, { useState } from "react";
import css from "./filter.module.css";
import { Field, Form } from "react-final-form";
import TextFieldSD from "../../../../shared/form/select-date";
import TextFieldMSG from "../../../../shared/form/messageBox";
import { Button, CircularProgress } from "@mui/material";
import { validateDate } from "../../../../../helpers/firebase/forms/validators";
import { useDispatch, useSelector } from "react-redux";
import { sendMessage } from "../../../../../store/slices/leads/thunks";
import AlpozoLogo from "../../../../../assets/alpozoLogo.png"
import BasicModal from "../../../../../helpers/modal/modal";
import BackButton from "../../../../shared/BackButton";

const FilterForm = () => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={css.formContainer}>
      <BasicModal open={isOpen} handleClose={()=>setIsOpen(false)}/>
      <img className={css.alpozoLogo} src={AlpozoLogo} alt="alpozo.com" />
      <BackButton />
      <Form
        onSubmit={async (values) => {
          //CATCH ERRORS
          let message = document.getElementById('boxMessage-textfield').value;
          const dateError = validateDate(values);
          if (dateError) return alert(dateError);
          if (!message)
            return alert("Es obligatorio ingresar un mensaje");
          console.log({...values, message});

          try {
            const response = await dispatch(sendMessage({...values, message}));
            console.log(response);
            setIsOpen(true);
          } catch (error) {
            console.error(error);
          }
        }}
        initialValues={{}}
        mutators={{}}
      >
        {({ form, handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} className={css.formWrap}>
              <div className={css.fieldsContainer}>
                <Field
                  className={css.input}
                  errorStyle={css.errorText}
                  name="startDate"
                  render={TextFieldSD}
                  label="Desde"
                />
                <Field
                  className={css.input}
                  name="endDate"
                  errorStyle={css.errorText}
                  render={TextFieldSD}
                  label="Hasta"
                />
              </div>
              <Field
                className={css.input}
                name="message"
                errorStyle={css.errorText}
                render={TextFieldMSG}
                label="MENSAJE A ENVIAR"
              />
              <div className={css.bottomContainer}>
                <Button
                  onClick={handleSubmit}
                  variant="text"
                  size="medium"
                  className={css.buttonForm}
                >
                  {submitting ? <CircularProgress size={10} /> : "Enviar"}
                </Button>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default FilterForm;
