import React, { useEffect, useState } from "react";
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersFromDB } from "../../../../store/slices/leads/thunks";
import BackButton from "../../../shared/BackButton";

const UserTable = () => {
  const dispatch = useDispatch();
  const { automaticUsers } = useSelector((state) => state.leads);

  // download data in pdf format
  const downloadData = () => {
    const pdf = new jsPDF();
    pdf.autoTable({ html: "#table" });
    pdf.save("usuarios_validados.pdf");
  };


  useEffect(() => {
    dispatch(getAllUsersFromDB())
  }, [dispatch])

  return (
    <div className="container">
        <BackButton />
        <div className="table-responsive" style={{ marginTop: 30 + "px" }}>
          <div className="download-data-div">
            <button className="btn btn-primary btn-md" onClick={downloadData}>
              DOWNLOAD PDF
            </button>
          </div>

          <h3>MENSAJES ENVIADOS AUTOMATICAMENTE</h3>

          <table className="table table-hover table-bordered" id="table">
            <thead className="table-dark">
              <tr>
                <th scope="col">ID</th>
                <th scope="col">NOMBRE/Publicación</th>
                <th scope="col">FECHA DE ENVIO</th>
                <th scope="col">TELEFONO</th>
                <th scope="col">STATUS</th>
              </tr>
            </thead>

            {automaticUsers.array && automaticUsers.array.map((individualData, i) => (
              <tbody key={individualData._id}>
                <tr>
                  <td>{i}</td>
                  <td>{individualData.name}</td>
                  <td>{`${new Date((individualData.sendDate.seconds * 1000)).toLocaleDateString()}`}</td>
                  <td>{individualData.phoneNumber}</td>
                  <td>{individualData.status ? 'Enviado' : 'No enviado'}</td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
    </div>
  );
};

export default UserTable;
