import React from "react";
import css from "./filters.module.css";
import { Link } from "react-router-dom";
import { filterList } from "../../../../helpers/data/constants";
import AlpozoLogo from "../../../shared/AlpozoLogo";
import BackButton from "../../../shared/BackButton";
const Filters = () => {
  return (
    <div className={css.filterContainer}>
      <AlpozoLogo />
      <BackButton />
      <div className={css.titleContainer}>
        <h2 className={css.filterTitle}>Listado de filtros disponibles</h2>
      </div>
      <ul className={css.listContainer}>
        {filterList.map((e) => (
          <li key={e.name} className={css.filterElement}>
            <Link to={e.to} className={css.filterLink}>
              {e.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Filters;
