import React, { useState } from 'react'
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import css from './CustomInput.module.css';

const CustomInput = ({setValue, cssClass = 'PRIMARY', placeholder = '', label}) => {
  const [innerValue, setInnervalue] = useState('');
    const handleTextCleaner = (e) => {
        e.preventDefault();
        setValue('');
        setInnervalue('');
    };
    return (
        <Box
          sx={{
            width: "100%",
            maxWidth: "100%",
            marginY: '20px'
          }}
        >
          {/* <p className={css[cssClass]}>{label}</p> */}
          <TextField
            id="customInput"
            fullWidth
            placeholder={placeholder}
            value={innerValue}
            onChange={(newValue) => {
              setValue(newValue.target.value);
              setInnervalue(newValue.target.value);
            }}
            InputProps={{
              endAdornment:
             <InputAdornment position="end">
              <IconButton
              aria-label="toggle password visibility"
              sx={{ visibility: innerValue ? "visible" : "hidden" }}
              onClick={(e) => handleTextCleaner(e)}
              >
              <CloseIcon />
              </IconButton>
             </InputAdornment>
            }}
    
            type={"input"}
          />
        </Box>
      );
}

export default CustomInput