import React from 'react'
import css from './AlpozoLogo.module.css';
import Logo from '../../../assets/alpozoLogo.png'

const AlpozoLogo = () => {
  return (
    <div className={css.logoContainer}>
        <img className={css.alpozoLogo} src={Logo} alt="alpozo.com" />
    </div>
  )
}

export default AlpozoLogo