import axios from 'axios';

const clientAxios = axios.create({
    //TEST
    // baseURL: `http://localhost:4000/`,

    //PRODUCTION
    baseURL: `http://52.6.181.231:4000/`,
    // timeout: 1000,
});

export default clientAxios;