import React, { useState } from "react";
import css from "./loginTitle.module.css";
const LoginTitle = () => {
  const [showTitle, setShowTitle] = useState(true);

    if(!showTitle) return (<div className={css.x} onClick={() => setShowTitle(!showTitle)}> x </div>);
  return (
    <div className={css.titleContainer} onClick={() => setShowTitle(!showTitle)}>
    <h1 className={css.title}>
      <span>L</span>
      <span>E</span>
      <span>A</span>
      <span>D</span>
      <span> </span>
      <span>V</span>
      <span>A</span>
      <span>L</span>
      <span>I</span>
      <span>D</span>
      <span>A</span>
      <span>T</span>
      <span>O</span>
      <span>R </span>
    </h1>
    </div>
  );
};

export default LoginTitle;
