import React from 'react'
import { useSelector } from 'react-redux';
import BackButton from '../BackButton';
import jsPDF from 'jspdf';
import 'jspdf-autotable'

const ShowErrorLeads = () => {
  const { errorLeadsList } = useSelector((state) => state.leads);

  const downloadData = () => {
    const pdf = new jsPDF();
    pdf.autoTable({ html: "#table" });
    pdf.save(`errorLeads_${new Date().toLocaleDateString()}.pdf`);
  };

    return (
        <div className="container">
            <BackButton />
            <div className="table-responsive" style={{ marginTop: 30 + "px" }}>
              <div className="download-data-div">
                <button className="btn btn-primary btn-md" onClick={downloadData}>
                  DESCARGAR USUARIOS FALLIDOS
                </button>
              </div>
    
              <h3>A ESTOS USUARIOS NO SE LES HA PODIDO ENVIAR EL MENSAJE</h3>
    
              <table className="table table-hover table-bordered" id="table">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">NOMBRE</th>
                    <th scope="col">FECHA</th>
                    <th scope="col">TELEFONO</th>
                    <th scope="col">EMAIL</th>
                  </tr>
                </thead>
    
                {errorLeadsList && errorLeadsList.map((individualData, i) => (
                  <tbody key={individualData.i}>
                    <tr>
                      <td>{i}</td>
                      <td>{individualData.name}</td>
                      <td>{`${new Date().toLocaleDateString()}`}</td>
                      <td>{individualData.phoneNumber}</td>
                      <td>{individualData.email}</td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
        </div>
      );
}

export default ShowErrorLeads