export const filterList = [
  { name: "Por Fecha", to: "enviador-de-wpps" },
  { name: "Por Región", to: "region" },
];

export const selectZone = [
  { value: "[LIT] FORMULARIOS", disable: false, label: "[LIT] FORMULARIOS" },
  { value: "[BA] FORMULARIOS", disable: false, label: "[BA] FORMULARIOS" },
  { value: "[INTERIOR] FORMULARIOS", disable: false, label: "[INTERIOR] FORMULARIOS" },
  { value: "PLAN IMPULSO - Formularios", disable: false, label: "PLAN IMPULSO - Formularios" },
  { value: "[UY] FORMULARIOS", disable: false, label: "[UY] FORMULARIOS" },
  { value: "[PY] FORMULARIOS", disable: false, label: "[PY] FORMULARIOS" },
  { value: "[NEUQUÉN] FORMULARIOS", disable: false, label: "[NEUQUÉN] FORMULARIOS" },
];
