import { createSlice } from "@reduxjs/toolkit";
import { getAllUsersFromDB, sendMessage, sendMessageByZone } from "./thunks";

export const initialState = {
  leadsList: [],
  errorLeadsList: [],
  isFetching: false,
  automaticUsers: [],
};

export const leadsSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendMessage.pending, (state) => {
      state.isFetching = true;
    });
        builder.addCase(sendMessage.rejected, (state) => {
        state.isFetching = false;
        });
        builder.addCase(sendMessage.fulfilled, (state, action) => {
        state.leadsList = action.payload.leads;
        state.errorLeadsList = action.payload.error;
        state.isFetching = false;
        });
    builder.addCase(sendMessageByZone.pending, (state) => {
      state.isFetching = true;
    });
        builder.addCase(sendMessageByZone.rejected, (state) => {
        state.isFetching = false;
        });
        builder.addCase(sendMessageByZone.fulfilled, (state, action) => {
        state.leadsList = action.payload.leads;
        state.errorLeadsList = action.payload.error;
        state.isFetching = false;
        });
    builder.addCase(getAllUsersFromDB.pending, (state) => {
      state.isFetching = true;
    });
        builder.addCase(getAllUsersFromDB.rejected, (state) => {
        state.isFetching = false;
        });
        builder.addCase(getAllUsersFromDB.fulfilled, (state, action) => {
        state.automaticUsers = action.payload;
        state.isFetching = false;
        });
  },
});

export default leadsSlice.reducer;
