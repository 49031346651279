import css from './style.module.css';
import React from 'react';
import { withStyles } from '@mui/styles';
import TextFieldMaterial from '@mui/material/TextField';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import InputAdornmentMaterial from '@mui/material/InputAdornment';
import cx from 'classnames';
import { IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import PropTypes from 'prop-types';

const CssTextField = withStyles(() => ({
  root: {
    '& .MuiInput-formControl': {
      border: '1px solid',
      backgroundColor: '#FFFFFF',
      borderColor: '#A7A7A7',
      height: 50,
      padding: '10px 10px',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Montserrat-Light',
      fontSize: '13px !important',
      fontWeight: 600,
      '&:focus': {
        backgroundColor: '#FFFFFF',
      },
    },
    '& .MuiInput-multiline': {
      minHeight: 36,
    },
    '& .MuiFormLabel': {},
    '& .MuiInput-underline:before': {
      borderBottom: 0,
      content: 'none',
    },
  },
}))(TextFieldMaterial);

const EndInputAdornment = withStyles(() => ({
  root: {
    '& .MuiSvgIcon-root': {
      height: 25,
      width: 25,
      color: '#A7A7A7',
    }
  },
})
)(InputAdornmentMaterial);


const CustomTextField = (
  {
    input,
    meta,
    label,
    disabled,
    isPasswordField,
    isNumberField,
    labelNumber,
    errorStyle,
    type,
    ...custom
  }
) => {
  const [showPassword, setShowPassword] = useState(false);
  const { touched, visited, error } = meta;

  return (
    <div className={css.containerTextField}>
      <CssTextField
        error={!!(touched && error)}
        disabled={disabled}
        placeholder={isNumberField && visited ? labelNumber : label}
        InputProps={{
          endAdornment: isPasswordField ?
            (<>
              <EndInputAdornment position="end">
                <IconButton
                  tabIndex={-1}
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </IconButton>
              </EndInputAdornment >
            </>
            ) : undefined
        }}
        type={isPasswordField ? showPassword ? 'text' : 'password' : type}
        {...input}
        {...custom}
      />
      {typeof (error) === 'string' ? (
        <div className={cx(css.textFieldGroupError, errorStyle)}>
          {!!(visited && touched && error) && (error)}
        </div>
      ) : error && error.length > 0 ? error.map((err, index) => (
        <div key={index} className={cx(css.passwordErrors, {
          [css.completed]: err.completed,
          [css.incompleted]: !err.completed
        })}>
          <div className={css.containerTextAndIcon}>
            {err.completed ? <CheckIcon /> : <CloseIcon />} {err.text}
          </div>
        </div>
      )) : null
      }
    </div>
  );
};

CustomTextField.propTypes = {
  input: PropTypes.any,
  meta: PropTypes.any,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isPasswordField: PropTypes.bool,
  isNumberField: PropTypes.bool,
  labelNumber: PropTypes.bool,
  errorStyle: PropTypes.string,
  type: PropTypes.string,
  showError: PropTypes.bool,
  custom: PropTypes.any
};

export default CustomTextField;