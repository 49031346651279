import { createAsyncThunk } from "@reduxjs/toolkit";
import clientAxios from "../../../config/axios";

const config = {
  "Access-Control-Allow-Origin": "*",
  origin: "x-requested-with",
  "Access-Control-Allow-Headers":
    "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
  "Content-Type": "application/json",
};

export const sendMessage = createAsyncThunk(
  "leads/sendMessage",
  async (values, { rejectWithValue }) => {
    try {
      const { startDate, endDate, message } = values;
      const response = await clientAxios.post(
        `api/sheet/getByDate?from=${startDate}&to=${endDate}`,
        { msg: message },
        config
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const sendMessageByZone = createAsyncThunk(
  "leads/sendMessageByZone",
  async (values, { rejectWithValue }) => {
    try {
      const { startDate, endDate, message, checked, zone, publication } = values;
      if (checked) {
        const response = await clientAxios.post(
          `api/sheet/getByDateAndZone?from=${startDate}&to=${endDate}`,
          { msg: message, zone, publication },
          config
        );
        return response.data;
      }
      const response = await clientAxios.post(
        `api/sheet/getByZone`,
        { msg: message, zone, publication },
        config
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

//CREAR LLAMADO A LA DB PARA TRAER A LOS USUARIOS DE LOS MENSAJES AUTOMATICOS

export const getAllUsersFromDB = createAsyncThunk(
  "leads/getAllUsersFromFirestore",
  async () => {
    try {
      const response = await clientAxios.get(`fb/getAllUsers`, config);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
    }
  }
);
