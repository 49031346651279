import React from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import css from './BackButton.module.css';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
    let navigate = useNavigate();

    const handleBackButton = () => {
        navigate(-1)
    }

  return (
    <div className={css.backButtonContainer} onClick={handleBackButton}>
    <ArrowBackIosIcon className={css.arrowIcon} />
    </div>
  )
}

export default BackButton