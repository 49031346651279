import React, { useState } from "react";
import { Button } from "@mui/material";
import { Field, Form } from "react-final-form";
import { selectZone } from "../../../../../helpers/data/constants";
import {
  required,
  validateDate,
} from "../../../../../helpers/firebase/forms/validators";
import css from "./filterbyzone.module.css";
import CustomInput from "../../../../shared/form/CustomInput";
import TextFieldMSG from "../../../../shared/form/messageBox";
import TextFieldSD from "../../../../shared/form/select-date";
import AlpozoLogo from "../../../../../assets/alpozoLogo.png";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch } from "react-redux";
import { sendMessageByZone } from "../../../../../store/slices/leads/thunks";
import BackButton from "../../../../shared/BackButton";
import BasicModal from "../../../../../helpers/modal/modal";

const FilterByZone = () => {
  const dispatch = useDispatch();
  
  const [zone, setZone] = useState('');
  const [publication, setPublication] = useState('');
  const [checked, setChecked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (event) => {
    event.preventDefault();
    setChecked(event.target.checked);
  };
  return (
    <div className={css.containerForm}>
      <BasicModal open={isOpen} handleClose={() => setIsOpen(false)} />
      <img className={css.alpozoLogo} src={AlpozoLogo} alt="alpozo.com" />
      <BackButton />
      <Form
        onSubmit={async (values) => {
          console.log(values);
          if (!zone) return alert("Es obligatorio ingresar una Zona/Región");
          let message = document.getElementById("boxMessage-textfield").value;
          if (checked) {
            const dateError = validateDate(values);
            if (dateError) return alert(dateError);
          }
          if (!message) return alert("Es obligatorio ingresar un mensaje");
          console.log({ ...values, message, zone, publication });
          console.log("CHECK", checked);

          try {
            const response = await dispatch(
              sendMessageByZone({ ...values, message, checked, zone, publication })
            );
            setIsOpen(true);
            console.log(response);
          } catch (error) {
            console.error(error);
          }
        }}
      >
        {({ handleSubmit, values }) => {
          return (
            <form className={css.form} onSubmit={handleSubmit}>
              <div className={css.fieldContainer}>
                <Field
                  className={css.input}
                  name="zone"
                  errorStyle={css.errorText}
                  render={CustomInput}
                  label="Zona"
                  cssClass='SECONDARY'
                  placeholder='Zona/Región'
                  value={zone}
                  setValue={setZone}
                />
                <Field
                  className={css.input}
                  name="publication"
                  errorStyle={css.errorText}
                  render={CustomInput}
                  label="Publicación"
                  cssClass='SECONDARY'
                  placeholder='Publicación'
                  value={publication}
                  setValue={setPublication}
                />
                {checked ? (
                  <>
                    <Field
                      className={css.input}
                      errorStyle={css.errorText}
                      name="startDate"
                      render={TextFieldSD}
                      label="Desde"
                    />
                    <Field
                      className={css.input}
                      name="endDate"
                      errorStyle={css.errorText}
                      render={TextFieldSD}
                      label="Hasta"
                    />
                  </>
                ) : null}
              </div>
              <Field
                className={css.input}
                name="message"
                errorStyle={css.errorText}
                render={TextFieldMSG}
                label="MENSAJE A ENVIAR"
              />
              <div className={css.checkboxContainer}>
                <p>
                  SELECCIONA ESTA OPCIÓN SI ADEMÁS QUIERES FILTRAR POR FECHA
                </p>
                <Checkbox
                  checked={checked}
                  onChange={(e) => handleChange(e)}
                  // inputProps={{ "aria-label": "controlled" }}
                  color="success"
                />
              </div>
              <div className={css.bottomContainer}>
                <Button
                  onClick={handleSubmit}
                  variant="text"
                  size="medium"
                  type="button"
                  text="Confirmar"
                >
                  Enviar
                </Button>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default FilterByZone;
