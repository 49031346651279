import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import css from './messages.module.css';

function MessageBox({ input, label }) {
  const [text, setText] = useState("");
  const handleTextCleaner = (e) => {
    e.preventDefault()
    setText('')
  };
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "100%",
      }}
    >
      <p className={""}>{label}</p>
      <TextField
        id="boxMessage-textfield"
        fullWidth
        multiline
        value={text}
        onChange={(newValue) => {
          setText(newValue.target.value);
        }}
        InputProps={{
          endAdornment:
         <InputAdornment position="end">
          <IconButton
          aria-label="toggle password visibility"
          sx={{ visibility: text ? "visible" : "hidden" }}
          onClick={(e) => handleTextCleaner(e)}
          >
          <CloseIcon />
          </IconButton>
         </InputAdornment>
        }}

        type={"input"}
      />
    </Box>
  );
}

export default MessageBox;
