import React from "react";
import { useDispatch } from "react-redux";
import { loginWithFirebase } from "../../../../../store/slices/auth/thunks";
import { useNavigate } from "react-router-dom";
import { Field, Form } from "react-final-form";
import {
  composeValidators,
  email,
  required,
} from "../../../../../helpers/firebase/forms/validators";
import TextField from '../../../../shared/form/text-field';
import { Button, CircularProgress } from "@mui/material";
import css from "./login.module.css";
import AlpozoLogo from "../../../../../assets/alpozoLogo.png";
import LoginTitle from "./loginTitle";

const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return (
    <div className={css.containerForm}>
      <img className={css.alpozoLogo} src={AlpozoLogo} alt="alpozo.com" />
      {/* ELIMINAR DESPUES DE TERMINAR EL HOME */}
      {/* <button onClick={()=>navigate("home")}>IR AL HOME</button> */}
      <LoginTitle />
      <Form
        onSubmit={async (values) => {
          if (values) {
            const { password, email } = values;
            try {
              const responseFirebase = await dispatch(
                loginWithFirebase({ email, password })
              );
              // auth errors catcher
              if (responseFirebase.error) {
                if (responseFirebase.error.code === "auth/wrong-password") {
                  // ACA SE DEBE CREAR UN MODAL AVISANDO QUE LA PASS ES ERRONEA
                  console.log("CONTRASEÑA INCORRECTA");
                }
                console.log(process.env.REACT_APP_API_KEY)
                console.log("HUBO UN ERROR DE AUTENTICACIÓN!!!");
              } else {
                navigate("home");
              }
            } catch (error) {
              console.log("HUBO UN ERROR DE AUTENTICACIÓN!!! (CATCH)");
            }
          }
        }}
        initialValues={{}}
        mutators={{}}
      >
        {({ form, handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div>
                <Field
                  className={css.input}
                  errorStyle={css.errorText}
                  name="email"
                  render={TextField}
                  validate={composeValidators(required, email)}
                  label="email"
                />
                <Field
                  className={css.input}
                  name="password"
                  errorStyle={css.errorText}
                  render={TextField}
                  validate={required}
                  isPasswordField={true}
                  label="Contraseña"
                />
                <div className={css.bottomContainer}>
                  <Button
                    onClick={handleSubmit}
                    variant="text"
                    size="medium"
                    className={css.buttonForm}
                  >
                    {submitting ? <CircularProgress size={10} /> : "Ingresar"}
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default Login;
