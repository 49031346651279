import React from 'react'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import css from './home.module.css';
import { useNavigate } from 'react-router-dom';
import AlpozoLogo from '../../../shared/AlpozoLogo';
function Home() {
    const navigate = useNavigate();

    const navigateToQRCode = (e) => {
        e.preventDefault();
        window.open(`${process.env.REACT_APP_QR_URI}`, '_blank')
    };
  return (
    //añadir el logo de alpozo.com
    <Container sx={containerStyle}>
        <AlpozoLogo />
        <Box component="div" sx={boxStyle}>
            <div className={`${css.divOption} ${css.qr}`} onClick={( e ) => navigateToQRCode(e)}>
                <p className={css.textBox}>GENERAR QR</p>
            </div>
            <div className={`${css.divOption} ${css.qr}`} onClick={() => navigate('error-leads')}>
                <p className={css.textBox}>ERROR LEADS</p>
            </div>
            <div className={`${css.divOption} ${css.filters}`} onClick={() => navigate('/filters')}>
                <p className={css.textBox}>FILTROS</p>
            </div>
            <div className={`${css.divOption} ${css.qr}`} onClick={() => navigate('user-table')}>
                <p className={css.textBox}>TABLA DE ENVIOS AUTOMÁTICOS</p>
            </div>
            
        </Box>
    </Container>
  )
}

// STYLES
const containerStyle = {height: '100%'};
const boxStyle = { display: 'flex', justifyContent: 'center', alignItems: 'center', 'flex-wrap': 'wrap'  };

export default Home